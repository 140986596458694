<template>
  <v-container>
    <span class="text-h4 text-center">ĐANG TẢI DỮ LIỆU VUI LÒNG ĐỢI....</span>
    <br />
    <Loader :status="loader" />
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import Loader from '@/components/Loader.vue';
export default {
  name: "Home",
  components:{
    Loader,
  },
  data(){
    return {
      checker: null,
      loader: true
    }
  },
  computed: {
    ...mapGetters(['authStatus', 'isAuthenticated', 'loginInfo']),
  },
  methods:{
    doCheckRedirect(){
      if(this.loginInfo && this.loginInfo._perm){
        if(this.loginInfo._perm === 'ctv'){
          return this.$router.push('/kho-hang/nganh-hang');
        }else{
          return this.$router.push('/shipments');
        }
      }
    },
  },
  beforeMount() {
    this.checker = setInterval(this.doCheckRedirect,1000);
  },
  beforeDestroy() {
    clearInterval(this.checker);
    this.checker = null;
  },
  mounted() {
    this.doCheckRedirect();
  }
}
</script>

<style scoped>

</style>